<template>
  
  <button class="custom-button" @click="goBack">后退</button>
  <router-view/>
</template>

<script>
export default ({
  name: '',
  components: {
  
  },
  setup() {
    function goBack() {
      history.go(-1);
    }
    return { goBack};
  }
})
</script>
<style>
.custom-button {
  width: 100%;
  max-width: 100vw;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.custom-button:hover {
  background-color: #45a049;
}

.custom-button:active {
  background-color: #3e8e41;
}
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50; */
  background-color: #f5f5f5;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

</style>
