<template>
    <div class="groups">
        <van-cell-group   class="group" inset>
            <van-cell @click="worker('所有人')"  class="cell"    title="所有人" value="进入"  is-link />
    
</van-cell-group>
       
  <van-cell-group :key="item" v-for="item in groups"  class="group" inset>
  <van-cell @click="worker(item)"  class="cell"    :title="item" value="进入"  is-link />

</van-cell-group>
    </div>

</template>

<script>
import { defineComponent } from 'vue';
import { Button as VanButton, Field as VanField } from 'vant';
import { Cell as VanCell, CellGroup as VanCellGroup } from 'vant';

import { reactive } from 'vue'
import { useRouter } from 'vue-router';
import axios from 'axios';
const baseUrl = require('../config/baseUrl');
import { ref, onMounted } from 'vue';
export default defineComponent (


    {
        name:'Groups',
        components: {
    VanButton, VanField,VanCell,VanCellGroup// 注册 Vant 的 Button 组件
  },
  setup() {

let groups= ref([]);
const router=useRouter()
const worker=(groups)=>{
    router.push('/worker/' + groups );

    console.log(groups)
}

    onMounted(() => {
        groups.value = JSON.parse(localStorage.getItem('groups')) || [];
      
      console.log(groups.value)
    });
    return {
        
    groups,worker
}
    
}}
)
</script>

<style scoped>
.about {
  width: 100vw;

  display: flex;
  flex-direction: column;
  
  align-items: center;
}
.group{
  margin-top: 20px;
  width: 90%;
}
</style>