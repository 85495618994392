import { createApp } from 'vue'
import App from './App.vue'
import { Field, CellGroup } from 'vant';

// 1. 引入你需要的组件
import { Button } from 'vant';
// 2. 引入组件样式



// 3. 注册你需要的组件

// 1. 引入你需要的组件
import vant from 'vant';

// 2. 引入组件样式
import 'vant/lib/index.css';

import router from './router'

const app = createApp(App).use(router).mount('#app')