<template>
  <div class="worker">

    <van-checkbox-group ref="checkboxGroup" v-model="checked">
      <van-cell-group inset>
        <van-cell v-for="(item, index) in worker" clickable :key="item.idCardNumber" :title="`${item.workerName}`"
          @click="toggle(index)">
          <template #right-icon>
            <van-checkbox :name="index" :ref="el => checkboxRefs[index] = el" @click.stop />

          </template>
        </van-cell>
      </van-cell-group>
    </van-checkbox-group>
  </div>



  <div class="bottomBox">

    <div class="buttonBox">
      <div @click="toggleAll()" class="leftBox">

        取消全选
      </div>
      <div @click="checkAll()" class="rightBox">

        确定全选
      </div>


    </div>

    <div @click="card()" class="cardBox">

      确定打卡
    </div>
  </div>

  <van-popup v-model:show="showBottom" position="bottom" :style="{ height: '60%' }" @click-overlay="onclose">

    <van-time-picker v-model="currentTime" title="选择时间" :min-hour="1" :max-hour="maxHour" :min-minute="10"
      :max-minute="maxMinute" @confirm="onConfirmTime" @cancel="onclose" @change="onChange" />
  </van-popup>
  <van-popup v-model:show="showStatus" position="bottom" :style="{ height: '60%' }" @click-overlay="onclose">

    <van-picker title="标题" :columns="columns" @confirm="onConfirmStatus" @cancel="onCancel" />
  </van-popup>
</template>

<script>
import { defineComponent } from 'vue';
import { Card, Button as VanButton, Field as VanField } from 'vant';
import { Picker as VanPicker, TimePicker as VanTimePicker, Popup as VanPopup, Cell as VanCell, CellGroup as VanCellGroup, Checkbox as VanCheckbox, CheckboxGroup as VanCheckboxGroup, } from 'vant';


import { reactive } from 'vue'
import { useRouter } from 'vue-router';
import axios from 'axios';
const baseUrl = require('../config/baseUrl');

import { ref, onBeforeUpdate, onMounted } from 'vue';


export default {
  components: {
    VanPopup, VanTimePicker, VanPicker,
    VanButton, VanField, VanCell, VanCellGroup, VanCheckbox, VanCheckboxGroup// 注册 Vant 的 Button 组件
  },
  setup() {
    const checked = ref([]);
    const checkboxRefs = ref([]);
    const groups = ref([])
    const allWorker = ref([])
    const worker = ref([])
    const showBottom = ref(false);
    const showStatus = ref(false);
    const currentTime = ref();
    const columns = ref([{ text: '入场', value: 1 }, { text: '出场', value: 2 }]);
    const checkboxGroup = ref(null);
    const maxHour = ref();
    const maxMinute = ref();

    const router = useRouter()
    const toggle = (index) => {
      checkboxRefs.value[index].toggle();
    };


    function card() {
      showBottom.value = true

    }
    const onConfirmTime = (e) => {
      console.log(e.selectedValues[1])
      showBottom.value = false
      showStatus.value = true

    }
    function checkAndFormatTime(time) {
      var currentDateTime = new Date(); // 获取当前的日期和时间
      var inputTime = new Date(); // 创建一个新的日期对象，用于存储输入的时间
      console.log(inputTime.getDate())
      var timeArray = time.split(':'); // 将输入的时间字符串拆分成小时和分钟的数组
      inputTime.setHours(parseInt(timeArray[0])); // 设置输入时间的小时部分
      inputTime.setMinutes(parseInt(timeArray[1])); // 设置输入时间的分钟部分

      // 检查输入时间是否超过当前时间并且晚于当前时间5分钟
      if (inputTime.getTime() - currentDateTime.getTime() > (5) * 60 * 1000) {
        return 0; // 如果不符合要求，退出函数
      }

      var currentTime = new Date();  // 当前日期和时间
      var year = currentTime.getFullYear();  // 年份
      var month = ('0' + (currentTime.getMonth() + 1)).slice(-2);  // 月份，补零操作
      var day = ('0' + currentTime.getDate()).slice(-2);  // 日，补零操作

      var dateTimeStr = year + '-' + month + '-' + day + ' ' + time + ':00';  // 组合日期和时间字符串
      return dateTimeStr;

    }
    const onConfirmStatus = (e) => {
      let time = currentTime.value[0] + ':' + currentTime.value[1]
      let direction = e.selectedValues[0]
      let date = checkAndFormatTime(time)
      const selectedWorkers = checked.value.map((index) => worker.value[index]);
      for(let i=0;i<selectedWorkers.length;i++){

        selectedWorkers[i].date=date
        selectedWorkers[i].direction=direction
      }


      let data={
        projectCode:selectedWorkers[0].projectCode,
        dataList:selectedWorkers
      }
      console.log(data   )
      baseUrl.Post('/isWorker/unloadIsWorker',data).then(response=>{

        alert(response.data.message)
       
      }).catch(err=>{
        alert('失败')
        console.log(err)
      })



      console.log(selectedWorkers)
      onclose()
    }




    onBeforeUpdate(() => {
      checkboxRefs.value = [];

    });
    const onclose = () => {
      showBottom.value = false
      showStatus.value = false
    };

    onMounted(() => {
      // 创建一个 Date 对象实例
      var currentDate = new Date();
      // 使用 Date 对象的 getHours() 方法获取当前小时数
      var currentHour = currentDate.getHours();
      var currentMinutes = currentDate.getMinutes();

      // 打印当前小时数
      maxHour.value = currentHour

      groups.value = router.currentRoute.value.params.groups;
      console.log(groups.value)
      let condition
      if (groups.value == '所有人') {
        condition = { status: 1 };
      } else {
        condition = {
          teamName: groups.value,
          status: 1
        };
      }
      allWorker.value = JSON.parse(localStorage.getItem('allWorker')) || [];

      worker.value = filter(condition, allWorker.value);

      console.log(worker)

    });
    const checkAll = () => {
      checkboxGroup.value.toggleAll(true);
    }
    const toggleAll = () => {
      checkboxGroup.value.toggleAll();
    }
    const onChange = (e) => {
      var nowTime = new Date()
      console.log(e)
      console.log()
      if (parseInt(e.selectedValues[0]) == nowTime.getHours()) {

        maxMinute.value = nowTime.getMinutes()
      }
      else {
        maxMinute.value = 55
      }


    }
    function filter(condition, data) {
      return data.filter(item => {
        return Object.keys(condition).every(key => {
          return String(item[key]).toLowerCase().includes(
            String(condition[key]).trim().toLowerCase())
        })
      })
    }
    return {
      list: ['a', 'b'], onclose, currentTime, columns, onChange,
      toggle, showBottom, showStatus, onConfirmTime, onConfirmStatus,
      checked, worker, toggleAll, checkAll, checkboxGroup, maxHour,
      checkboxRefs, filter, card, maxMinute
    };
  },
};
</script>

<style>
.bottomBox {

  color: #ffffff;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 75px;
  position: fixed;
  bottom: 0;
  width: 100%;

  display: flex;
}

.buttonBox {
  width: 50%;
  display: flex;
}

.leftBox {
  width: 50%;
  height: 75px;
  background-color: #7232dd;
}

.rightBox {
  width: 50%;
  height: 75px;
  background-color: #FC5531;
}

.cardBox {
  width: 50%;
  height: 75px;
  background-color: #00B26A;
}
</style>