const axios = require('axios');
const baseUrl = "https://realnamesystem.fzlongcheng.top/api"
const Post = async(url, data) => {
    return new Promise((resolve, reject) => {
        const requestData = {
            url: baseUrl + url,
            data
        };
        axios.post(baseNodeUrl + "/api/post", requestData, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8"
                }
            })
            .then(response => {
                console.log(response.data);
                resolve(response);
            })
            .catch(error => {
                console.error(error);
                reject(error);
            });
    });
};
const Get = async(url, data) => {
    return new Promise((resolve, reject) => {
        const requestData = {
            url: baseUrl + url,
            data
        };
        axios.post(baseNodeUrl + "/api/get", requestData, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8"
                }
            })
            .then(response => {
                console.log(response);
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
};
// const baseUrl="http://122.51.50.62:7000/api"
const baseNodeUrl = "http://122.51.50.62:8772"
    // const baseNodeUrl="http://127.0.0.1:5000"
module.exports = {
    baseUrl,
    baseNodeUrl,
    Post,
    Get
}