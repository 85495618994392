<template>
  <div class="home">
    <div>
      <van-field v-model="username" label="用户名" placeholder="请输入用户名" />
    </div>
    <div>
      <van-field v-model="password" label="密码" placeholder="请输密码" />
    </div>
    <div>
      <van-button @click="login()" type="primary">登录</van-button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Button as VanButton, Field as VanField } from 'vant';
import { reactive } from 'vue'
import { useRouter } from 'vue-router';
import axios from 'axios';


const baseUrl = require('../config/baseUrl');
import { ref, onMounted ,onBeforeMount  } from 'vue';
export default defineComponent({
  name: 'HomeView',
  components: {
    VanButton, VanField// 注册 Vant 的 Button 组件
  },

  setup() {
    const router = useRouter();
    const password = ref('');
    const username = ref('');
    function login() {
      const requestData = {
  url: baseUrl.baseUrl+'/user/login', // 将请求转发到的目标 URL
  data: {
    // 请求数据
    username: username.value,
        password: password.value
  }
};



baseUrl.Post('/user/login',requestData.data).then(response=>{
          console.log(response);
          if (response.data.code === 40000) {
            // 使用 Vue.js 的消息提示框
            // this.$message.error(response.data.message);
          } else if (response.data.code == 0) {
            // 使用 Vue.js 的本地存储
            localStorage.setItem("username", username.value);
            localStorage.setItem("password", password.value);
            // 处理项目信息
            let array = response.data.data.projectInfo.map(item => item.projectCode);
            console.log(array);
            // 存储数组
            localStorage.setItem('projectCodeData', JSON.stringify(response.data.data.projectInfo));
            // 存储普通值
            localStorage.setItem('userId', response.data.data.id);
            // 使用 Vue.js 的页面跳转
            router.push('/about');
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
 
    const executed = sessionStorage.getItem('executed');


  onBeforeMount(() => {
    const storedUsername = localStorage.getItem('username');
    const storedPassword = localStorage.getItem('password');

    console.log(storedUsername);
    if (storedUsername !== '' && storedPassword !== '') {
      username.value = storedUsername;
      password.value = storedPassword;
      if (!executed) {
        login(); // 调用登录方法
      }
      
    }

    sessionStorage.setItem('executed', 'true'); // 标记已经执行过
  });


    return { password, username, login };
  },
});
</script>

<style scoped>
.home {
  width: 100vw;
  /* 100% 的视口宽度 */
  height: 100vh;
  /* 100% 的视口高度 */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>