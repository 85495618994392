<template>
    <div class="home">
  
     <h1>{{ projectName }}</h1>
     <h3>{{ projectCode }}</h3>
      <div>
        <van-button @click="card()" type="primary">打卡</van-button>
      </div>
      <div>
        <van-button @click="syncWorker()" type="primary">同步</van-button>
      </div>
    </div>
    <van-overlay style="z-index: 1000;" :show="showHide" >
    <div  style="z-index: 1000;display: flex;width: 100%; height: 100%; align-items: center;justify-content: center;">
      <van-loading type="spinner" size="80px" color="#1989fa" />
    </div>

  </van-overlay>>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  import { Button as VanButton, Field as VanField } from 'vant';
  import { reactive } from 'vue'
  import { useRouter } from 'vue-router';
  import axios from 'axios';
  import { Overlay  as VanOverlay ,Loading  as VanLoading } from 'vant';

  
  const baseUrl = require('../config/baseUrl');
  import { ref, onMounted } from 'vue';
  export default defineComponent({
    name: 'Index',
    components: {
      VanButton, VanField,VanOverlay,VanLoading// 注册 Vant 的 Button 组件
    },
  
    setup() {
      const router = useRouter();
    const projectCode=ref();
    const projectName=ref();



function card(){

  router.push('/groups');

};

    const showHide = ref(false);
    const waitData = ref(true);


    // 同步数据
    const syncWorker = async () => {
      if (waitData.value) {
        showHide.value = true;
        waitData.value = false;

        let url='/worker/getWorkerInfo?projectCode=' +projectCode.value
       let data
      await baseUrl.Get(url).then(res=>{
        console.log(res)
        data=res.data
       })
      
        // 使用 async/await 或者 Promise 可以更好地处理异步请求
        try {
        
        
          if (data.code === 0) {
            showAlert("同步完成");
  
            setTimeout(() => {
              getAllWorkeragain();
            }, 1000);
          } else {
            showAlert("省平台出错啦，请等待一两个小时之后再重试。", 'error');
          }
        } catch (error) {
          showAlert("同步失败", 'error');
          showHide.value = false;
          waitData.value = true;
          console.log(error);
        }
      }
    };

    const showAlert = (message, type = 'success') => {
      // 使用原生的 alert 函数来显示提示信息
      alert(message);
    };

    const getAllWorkeragain=async () => {
      showHide.value = true;
      let url='/worker/getWorkerInfo?projectCode=' +projectCode.value
       let data
      await baseUrl.Get(url).then(res=>{
        console.log(res)
        data=res.data
       })
      // 使用 async/await 或者 Promise 可以更好地处理异步请求
      try {
        // const response = await fetch(baseUrl.baseUrl + '/worker/getWorkerInfo?projectCode=' + projectCode.value);
        // const data = await response.json();

        console.log(data);

        if (data.data.length === 0) {
          showAlert('无人员');
          // 使用其他库或组件来显示提示信息，比如 Element Plus 或者 Ant Design Vue
          // Dialog.alert({
          //   title: '请注意',
          //   message: '当前数据库无数据，请先点击同步数据',
          //   theme: 'round-button',
          // }).then(() => {
          //   // on close
          // });
        }

        if (data.code === 0) {
          showAlert(data.message);
        } else {
          showAlert(data.message, 'error');
        }

        console.log(data);
     
        let allWorker = data.data;
        let arr = [];

        for (let i = 0; i < allWorker.length; i++) {
          arr.push(allWorker[i].teamName);
        }

        let set = new Set(arr);
        let newArr = Array.from(set);


        localStorage.setItem("allWorker", JSON.stringify(data.data));
        localStorage.setItem("groups",  JSON.stringify(newArr));
        showHide.value = false;
        waitData.value = true;
      } catch (error) {
        showAlert("查询失败", 'error');
      }
    };
  



    const getAllWorker =async () => {
      showHide.value = true;
     
      // 使用 async/await 或者 Promise 可以更好地处理异步请求
      try {
        let url='/worker/getWorkerInfo?projectCode=' +projectCode.value
       let data
      await baseUrl.Get(url).then(res=>{
        console.log(res)
        data=res.data
       })
        if (data.data.length === 0) {
          syncWorker();
          // 使用其他库或组件来显示提示信息，比如 Element Plus 或者 Ant Design Vue
          // Dialog.alert({
          //   title: '请注意',
          //   message: '当前数据库无数据，请先点击同步数据',
          //   theme: 'round-button',
          // }).then(() => {
          //   // on close
          // });
        }

        if (data.code === 0) {
          showAlert(data.message);
        } else {
          showAlert(data.message, 'error');
        }

  
      
        let allWorker = data.data;
        let arr = [];

        for (let i = 0; i < allWorker.length; i++) {
          arr.push(allWorker[i].teamName);
        }

        let set = new Set(arr);
        let newArr = Array.from(set);

        localStorage.setItem("allWorker", JSON.stringify(data.data));
        localStorage.setItem("groups",  JSON.stringify(newArr));
        showHide.value = false;
        waitData.value = true;
      } catch (error) {
        showAlert("查询失败", 'error');
      }
    };


      onMounted(() => {
       projectCode.value = router.currentRoute.value.params.projectCode;
        projectName.value = router.currentRoute.value.params.projectName;
        getAllWorker()
      });
  
      return { projectCode,projectName,  showHide,
      waitData,getAllWorker,card,
      syncWorker};
    },
  });
  </script>
  
  <style scoped>
  .home {
    width: 100vw;
    /* 100% 的视口宽度 */
    height: 100vh;
    /* 100% 的视口高度 */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  </style>