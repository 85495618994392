import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/Login.vue'
import Index from '../views/Index.vue'
import Groups from '../views/Groups.vue'
import Worker from '../views/Worker.vue'


const routes = [{
        path: '/',
        name: 'home',
        component: HomeView
    }, {
        path: '/worker/:groups',
        name: 'worker',
        component: Worker
    },
    {
        path: '/Groups',
        name: 'groups',
        component: Groups
    }, {
        path: '/index/:projectCode/:projectName',
        name: 'index',
        component: Index
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Project.vue')
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router